.btn:focus,
.btn:active {
    box-shadow: none;
    outline: none;
    color: #fff;
}

.btn-unlock-wallet {
    margin-top: 20px !important;
    border: 2px solid #53b1c6 !important;
    background: transparent !important;
    position: relative;
    color: #008ead !important;
    z-index: 1;
    border-radius: 30px !important;
    width: 200px !important;
}

.btn-unlock-wallet:hover {
    color: #282b2d !important;
    background: #31bfdf !important;
    border-color: #31bfdf !important;
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
    cursor: pointer;
    width: 200px !important;
}

.btn-border {
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
}

.btn-border:hover {
    border: 2px solid #fff;
    color: #fff;
    background-color: #31bfdf;
}

.btn-lg {
    padding: 14px 33px;
    text-transform: uppercase;
    font-size: 16px;
}

.btn-rm {
    padding: 7px 0px;
    color: #999;
    text-transform: capitalize;
}

.btn-rm i {
    vertical-align: middle;
}

button:focus {
    outline: none !important;
}

.fa_wallet_icon {
    margin-right: 8px;
}

.typography_description {
    padding-top: 16px;
    color: #282b2d !important;
    text-align: center !important;
}
