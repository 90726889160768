.card {
    width: 800px;
    display: inline-block;
    margin: 2px;
}

@media only screen and (max-width: 800px) {
    .card {
        width: 94% !important;
        display: inline-block !important;
        margin: 2px !important;
    }
    .footer_main_form {
        width: 94% !important;
        display: inline-block !important;
        padding-top: 8px !important;
        padding-bottom: 16px !important;
    }
}

.footer_main_form {
    width: 800px;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 16px;
}

.grid_cell {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.card_header {
    background: #282b2d;
}

.card_header_text {
    float: left;
    color: white !important;
    opacity: 0.60 !important;
}

.card_content {
    background: #f5f5f6;
}

.btn {
    font-size: 20px;
    padding: 11px 40px;
    border-radius: 0px;
    font-weight: 400;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    display: inline-block;
    text-decoration: none;
}

.btn:focus,
.btn:active {
    box-shadow: none;
    outline: none;
    color: #fff;
}

.btn-common-extension {
    margin-top: 20px !important;
    border: 2px solid #53b1c6;
    background: transparent;
    position: relative;
    color: #008ead !important;
    z-index: 1;
    border-radius: 30px !important;
    width: 200px !important;
}

.btn-common-extension:hover {
    color: #282b2d !important;
    background: #31bfdf;
    border-color: #31bfdf;
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
    cursor: pointer;
    width: 200px !important;
}

.btn-confirm {
    border: 1px solid #53b1c6;
    background: #53b1c6;
    position: relative;
    color: #282b2d !important;
    z-index: 1;
    border-radius: 30px !important;
    width: 100px;
}

.btn-confirm:hover {
    color: #282b2d !important;
    background: #31bfdf;
    border-color: #008ead;
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
    cursor: pointer;
    width: 100px;
}

.btn-cancel {
    border: 1px solid #c65953;
    background: #c65953;
    position: relative;
    color: white !important;
    z-index: 1;
    border-radius: 30px !important;
    width: 100px;
}

.btn-cancel:hover {
    color: white !important;
    background: #df3a31;
    border-color: #df3a31;
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
    cursor: pointer;
    width: 100px;
}

.btn-border {
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
}

.btn-border:hover {
    border: 2px solid #fff;
    color: #fff;
    background-color: #31bfdf;
}

.btn-lg {
    padding: 14px 33px;
    text-transform: uppercase;
    font-size: 16px;
}

.btn-rm {
    padding: 7px 0px;
    color: #999;
    text-transform: capitalize;
}

.btn-rm i {
    vertical-align: middle;
}

button:focus {
    outline: none !important;
}

.video-popup {
    margin-top: 20px;
    width: 80px;
    height: 80px;
    text-align: center;
    border: 3px solid #53b1c6;
    color: #53b1c6;
    border-radius: 50%;
    display: inline-block;
    font-size: 30px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.video-popup:hover {
    cursor: pointer;
}

.play-icon {
    padding-top: 26px;
    padding-left: 8px;
}

.play-icon:hover {
    padding-top: 26px;
    padding-left: 8px;
    color: #31bfdf;
}

.mfp-hide {
    display: none !important;
    max-width: 75%;
    margin: 0 auto;
}

.tooltip_metamask {
    font-size: 14px !important;
    text-align: center !important;
}

.fa_back_icon {
    margin-right: 8px;
}

.fa_confirm_icon {
    margin-left: 8px;
}
