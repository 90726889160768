.App {
  text-align: center;
  width: 800px;
  display: block;
  align-content: center;
  margin: auto;
}

@media only screen and (max-width: 800px) {
  .App {
      width: 100% !important;
      display: inline-block !important;
      margin: 2px !important;
  }
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.copyright {
  font-size: 14px;
  color: darkgray;
  height: 40px;
}
