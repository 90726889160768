.card {
    width: 800px;
    display: inline-block;
    margin: 4px;
}

@media only screen and (max-width: 800px) {
    .card {
        width: 94% !important;
        display: inline-block !important;
        margin: 4px !important;
    }
    .typography_left {
        color: #282b2d !important;
        float: left !important;
        font-weight: bold !important;
    }
    .footer_main_form {
        width: 94% !important;
        display: inline-block !important;
        padding-top: 8px !important;
        padding-bottom: 16px !important;
    }
    .btn-confirm {
        border: 1px solid #53b1c6 !important;
        background: #53b1c6 !important;
        position: relative !important;
        color: #282b2d !important;
        z-index: 1 !important;
        border-radius: 30px !important;
        width: 50px !important;
    }
    .btn-confirm-disabled {
        border: 1px solid grey !important;
        background: transparent !important;
        position: relative !important;
        color: grey !important;
        z-index: 1 !important;
        border-radius: 30px !important;
        width: 50px !important;
    }
    .btn-cancel {
        border: 1px solid #c65953 !important;
        background: #c65953 !important;
        position: relative !important;
        color: white !important;
        z-index: 1 !important;
        border-radius: 30px !important;
        width: 50px !important;
    }
}

.card_header {
    background: #282b2d;
}

.card_header_text {
    float: left;
    color: white !important;
    opacity: 0.60 !important;
}

.card_content {
    background: #f5f5f6;
    height: auto;
}

.typography_left {
    color: #282b2d !important;
    float: right;
    font-weight: bold !important;
}

.typography_right {
    color: #282b2d !important;
    float: left;
    overflow: hidden;
    margin: 0px !important;
}

.typography_right_err {
    color: #df3a31 !important;
    float: left;
    overflow: hidden;
    margin: 0px !important;
}

.typography_right_root {
    font: bold !important;
}

.grid_container {
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
}

.grid_cell {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.footer_main_form {
    width: 800px;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 16px;
}

.fa_back_icon {
    margin-right: 8px;
}

.fa_confirm_icon {
    margin-left: 8px;
}

.btn {
    font-size: 20px;
    padding: 11px 40px;
    border-radius: 0px;
    font-weight: 400;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    display: inline-block;
    text-decoration: none;
}

.btn:focus,
.btn:active {
    box-shadow: none;
    outline: none;
    color: #fff;
}

.btn-confirm {
    border: 1px solid #53b1c6;
    background: #53b1c6;
    position: relative;
    color: #282b2d !important;
    z-index: 1;
    border-radius: 30px !important;
    width: 100px;
}

.btn-confirm:hover {
    color: #282b2d !important;
    background: #31bfdf;
    border-color: #008ead;
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
    cursor: pointer;
}

.btn-confirm-disabled {
    border: 1px solid grey;
    background: transparent;
    position: relative;
    color: grey !important;
    z-index: 1;
    border-radius: 30px !important;
    width: 100px;
}

.btn-confirm-disabled:hover {
    cursor: default;
}

.btn-cancel {
    border: 1px solid #c65953;
    background: #c65953;
    position: relative;
    color: white !important;
    z-index: 1;
    border-radius: 30px !important;
    width: 100px;
}

.btn-cancel:hover {
    color: white !important;
    background: #df3a31;
    border-color: #df3a31;
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
    cursor: pointer;
}

.btn-border {
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
}

.btn-border:hover {
    border: 2px solid #fff;
    color: #fff;
    background-color: #31bfdf;
}

.btn-lg {
    padding: 14px 33px;
    text-transform: uppercase;
    font-size: 16px;
}

.btn-rm {
    padding: 7px 0px;
    color: #999;
    text-transform: capitalize;
}

.btn-rm i {
    vertical-align: middle;
}

button:focus {
    outline: none !important;
}
