.footer_main_form {
    width: 800px;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 16px;
}

@media only screen and (max-width: 800px) {
    .footer_main_form {
        width: 94% !important;
        display: inline-block !important;
        padding-top: 8px !important;
        padding-bottom: 16px !important;
    }
}

.btn {
    font-size: 20px;
    padding: 11px 40px;
    border-radius: 0px;
    font-weight: 400;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    display: inline-block;
    text-decoration: none;
}

.btn:focus,
.btn:active {
    box-shadow: none;
    outline: none;
    color: #fff;
}

.btn-common-mint {
    border: 1px solid #53b1c6;
    background: #53b1c6;
    position: relative;
    color: #282b2d !important;
    z-index: 1;
    border-radius: 30px !important;
}

.btn-common-mint:hover {
    color: #282b2d !important;
    background: #31bfdf;
    border-color: #008ead;
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
    cursor: pointer;
}

.btn-disabled-mint {
    border: 1px solid grey;
    background: transparent;
    position: relative;
    color: grey !important;
    z-index: 1;
    border-radius: 30px !important;
}

.btn-disabled-mint:hover {
    cursor: default;
}

.btn-border {
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
}

.btn-border:hover {
    border: 2px solid #fff;
    color: #fff;
    background-color: #31bfdf;
}

.btn-lg {
    padding: 14px 33px;
    text-transform: uppercase;
    font-size: 16px;
}

.btn-rm {
    padding: 7px 0px;
    color: #999;
    text-transform: capitalize;
}

.btn-rm i {
    vertical-align: middle;
}

button:focus {
    outline: none !important;
}

.clear {
    clear: both;
}

.fa_coins {
    margin-right: 8px;
}

.tooltip_disabled {
    font-size: 14px !important;
    text-align: center !important;
}
