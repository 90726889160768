.card {
  width: 800px;
  display: inline-block;
  margin: 4px;
}

@media only screen and (max-width: 800px) {
  .card {
    width: 94% !important;
    display: inline-block !important;
    margin: 2px !important;
  }
}

.card_header {
  background: #282b2d;
}

.card_header_text {
  float: left;
  color: white !important;
  opacity: 0.60 !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  line-height: 1.35417em !important;
}

.card_content {
  background: #f5f5f6;
  height: auto;
}

.fa_info_icon {
  color: #31df51 !important;
  opacity: 0.60 !important;
}
