.select_field {
    background: white;
    width: 300px;
    margin-top: 16px;
    margin-bottom: 16px;
}

@media only screen and (max-width: 800px) {
    .select_field {
        background: white !important;
        width: 250px !important;
        margin-top: 16px !important;
        margin-bottom: 0px !important;
    }
}

.typography_payment {
    padding-top: 20px;
    color: #282b2d !important;
}

.typography_payment_error {
    padding-top: 10px;
    color: #df3a31 !important;
}

.typography_error_secondary {
    padding-top: 8px;
    color: #df3a31 !important;
}
