html {
    min-height: 100%;
    margin: 0;
}

body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: #f0f0f0;
}
