.modal-text {
    text-align: left;
}

.close-modal-button {
    float: right !important;
}

.dialog-content {
    margin: 0 auto !important;
}

@media only screen and (max-width: 800px) {
    .dialog-content {
        margin: 0 auto !important;
        width: 94% !important;
    }
}
