.card {
    width: 800px;
    display: inline-block;
    margin: 4px;
}

@media only screen and (max-width: 800px) {
    .card {
        width: 94% !important;
        display: inline-block !important;
        margin: 2px !important;
    }
    .footer_main_form {
        width: 94% !important;
        display: inline-block !important;
        padding-top: 8px !important;
        padding-bottom: 16px !important;
    }
}

.card_header {
    background: #282b2d;
}

.card_header_text {
    float: left;
    color: white !important;
    opacity: 0.60 !important;
    font-size: 1.5rem !important;
    font-weight: 400 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    line-height: 1.35417em !important;
}

.card_content {
    background: #f5f5f6;
    height: auto;
}

.btn {
    font-size: 20px;
    padding: 11px 40px;
    border-radius: 0px;
    font-weight: 400;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    display: inline-block;
    text-decoration: none;
}

.btn:focus,
.btn:active {
    box-shadow: none;
    outline: none;
    color: #fff;
}

.btn-success-back {
    border: 1px solid #53b1c6;
    background: #53b1c6;
    position: relative;
    color: #282b2d !important;
    z-index: 1;
    border-radius: 30px !important;
}

.btn-success-back:hover {
    color: #282b2d !important;
    background: #31bfdf;
    border-color: #008ead;
    transition: all .50s ease-in-out;
    -moz-transition: all .50s ease-in-out;
    -webkit-transition: all .50s ease-in-out;
    cursor: pointer;
}

.btn-border {
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
}

.btn-border:hover {
    border: 2px solid #fff;
    color: #fff;
    background-color: #31bfdf;
}

.btn-lg {
    padding: 14px 33px;
    text-transform: uppercase;
    font-size: 16px;
}

.btn-rm {
    padding: 7px 0px;
    color: #999;
    text-transform: capitalize;
}

.btn-rm i {
    vertical-align: middle;
}

button:focus {
    outline: none !important;
}

.fa_success_icon {
    color: #31df51;
    opacity: 0.60 !important;
}

.footer_main_form {
    width: 800px;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 16px;
}

.typography_success_info_message {
    padding-top: 20px;
    color: #000002 !important;
}

.typography_success_info_message_root {
    text-overflow: ellipsis !important;
    display: block !important;
    overflow: hidden !important;
}

.fa_clipboard {
    margin-left: 8px;
    color: #008ead;
}

.fa_clipboard:hover {
    cursor: pointer;
    color: #31bfdf;
}

.fa_coins {
    margin-right: 8px;
}

.button_share {
    width: 36px;
    padding-left: 2px;
    padding-right: 2px;
    margin: 4px auto;
}

.button_share:hover {
    cursor: pointer;
}

.share_div {
    padding-top: 8px;
    margin: 0px auto;
    display: inline-block;
}
