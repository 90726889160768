.card {
    width: 800px;
    display: inline-block;
    margin: 2px;
}

@media only screen and (max-width: 800px) {
    .card {
        width: 94% !important;
        display: inline-block !important;
        margin: 2px !important;
    }
    .radio_group_token_type {
        padding-right: 0px !important;
        margin: auto !important;
        float: left !important;
    }
    .typography_token_type_erc20 {
        padding-top: 14px !important;
        color: #282b2d !important;
        font-size: 0.875rem !important;
    }
    .typography_token_type_erc223 {
        padding-top: 20px !important;
        color: #282b2d !important;
        font-size: 0.875rem !important;
    }
}

.card_header {
    background: #c09c13;
    padding-top: 5px;
}

.card_header_text {
    float: left;
    color: white !important;
    opacity: 0.60 !important;
}

.card_content {
    background: #f5f5f6;
}

.typography_token_type_erc20 {
    padding-top: 10px;
    color: #282b2d !important;
    font-size: 0.875rem !important;
}

.typography_token_type_erc223 {
    padding-top: 31px;
    color: #282b2d !important;
    font-size: 0.875rem !important;
}

.radio_group_token_type {
    padding-right: 8px;
}

.radio_button {
    color: #008ead !important;
}
