.logo_header {
    float: left;
}

.header_div {
    width: 800px;
    display: inline-block;
    box-sizing: border-box;
    height: 64px;
}

.typography_header {
    vertical-align: middle;
    padding: 18px;
    color: black;
    position: relative;
    margin: 0px !important;
}

.logo_div {
    position: relative;
}

.caption_div {
    position: relative;
    overflow: auto;
    float: right;
}

.ethereum_div {
    position: relative;
    overflow: auto;
    float: right;
}

.typography_div {
    position: relative;
    overflow: auto;
    float: right;
}
