.card {
    width: 800px;
    display: inline-block;
    margin: 4px;
}

@media only screen and (max-width: 800px) {
    .card {
        width: 94% !important;
        display: inline-block !important;
        margin: 2px !important;
    }
    .text_field {
        background: white !important;
        width: 250px !important;
    }
    .typography_token_info {
        padding-top: 0px !important;
        color: #282b2d !important;
    }
    .typography_token_info_error {
        padding-top: 0px !important;
        color: #df3a31 !important;
    }
}

.card_header {
    background: #282b2d;
}

.card_header_text {
    float: left;
    color: white !important;
    opacity: 0.60 !important;
}

.card_content {
    background: #f5f5f6;
}

.text_field {
    background: white;
    width: 300px;
}

.text_field_decimals {
    background: white;
    width: 100px;
}

.typography_token_info {
    padding-top: 20px;
    color: #282b2d !important;
}

.typography_token_info_error {
    padding-top: 20px;
    color: #df3a31 !important;
}
